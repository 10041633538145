<template>
  <b-card
      class="purchasepayment-edit-wrapper"
  >
    <!-- form -->
    <ValidationObserver ref="observer">
    <b-form id="purchasepaymentForm" class="edit-form mt-2">
      <b-row>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="付款单编号"
              label-for="order_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="order_no"
                size="sm"
                v-model="purchasepayment.order_no"
                readonly
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">付款信息</div>
        </b-col>

        <b-col md="12">
          <b-card header="付款信息">
            <b-row>
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="付款主体ID"-->
<!--                    label-for="pay_company_id"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="pay_company_id"-->
<!--                      size="sm"-->
<!--                      v-model="purchasepayment.pay_company_id"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款主体"
                    label-for="pay_company_name"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required" name="付款主体" #default="{ errors }">
                    <v-select
                        id="pay_company_name"
                        :options="companys"
                        :clearable="true"
                        class="select-size-sm"
                        v-model="ourCompanyName"
                        @input="setOurCompanyName($event)"
                        readonly=""
                        placeholder="请选择付款主体名称"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="采购主体ID"-->
<!--                    label-for="purchase_company_id"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="purchase_company_id"-->
<!--                      size="sm"-->
<!--                      v-model="purchasepayment.purchase_company_id"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="采购主体"
                    label-for="purchase_company_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="purchase_company_name"
                      size="sm"
                      v-model="purchasepayment.purchase_company_name"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="采购订单编号"
                    label-for="purchase_order_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="purchase_order_no"
                      size="sm"
                      v-model="purchasepayment.purchase_order_no"
                      readonly
                  />
                </b-form-group>
              </b-col>
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="采购订单id"-->
<!--                    label-for="purchase_order_id"-->
<!--                    label-size="sm"-->
<!--                    class="mb-1"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="purchase_order_id"-->
<!--                      size="sm"-->
<!--                      v-model="purchasepayment.purchase_order_id"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="业务类型"
                    label-for="type_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="type_id"
                      size="sm"
                      v-model="typeId"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款条件"
                    label-for="pay_condition"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="pay_condition"
                      size="sm"
                      v-model="purchasepayment.pay_condition"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="物流单据类型"
                    label-for="express_ticket_type"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required" name="物流单据类型" #default="{ errors }">
                    <v-select
                        id="express_ticket_type"
                        :options="getCodeOptions('express_ticket_type')"
                        :clearable="true"
                        v-model="expressTicketType"
                        @input="changeSelect('express_ticket_type',$event)"
                        class="select-size-sm"
                        placeholder="请选择物流单据类型"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收料ERP单号"
                    label-for="delivery_no_erp"
                    label-size="sm"
                    class="mb-1"
                >
                    <b-form-input
                        id="delivery_no_erp"
                        size="sm"
                        v-model="purchasepayment.delivery_no_erp"
                        placeholder="请填写收料ERP单号"
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发票类型"
                    label-for="invoice_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="invoice_type"
                      size="sm"
                      v-model="invoiceType"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="采购发票税率"
                    label-for="tax_rate"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required" name="发票税率" #default="{ errors }">
                    <v-select
                        id="tax_rate"
                        :options="getCodeOptions('tax_rate')"
                        :clearable="true"
                        v-model="taxRate"
                        @input="changeSelect('tax_rate',$event)"
                        class="select-size-sm"
                        placeholder="请选择发票税率"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否收到采购发票"
                    label-for="is_invoice"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="is_invoice"
                      v-model="purchasepayment.is_invoice"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="应付金额"
                    label-for="total_pay"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="total_pay"
                      size="sm"
                      v-model="total_pay"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款方式"
                    label-for="payment_method"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="payment_method"
                      size="sm"
                      v-model="paymentMethod"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否冲销"
                    label-for="is_prepay"
                    label-size="sm"
                    class="mb-1"
                >
                  <ValidationProvider rules="required" name="是否冲销" #default="{ errors }">
                    <v-select
                        id="is_prepay"
                        :options="getCodeOptions('yesno')"
                        :clearable="true"
                        v-model="is_prepay"
                        @input="changeSelect('is_prepay',$event)"
                        class="select-size-sm"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4" v-if="purchasepayment.ext && is_prepay.value === '1'">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="剩余额度"
                    label-for="last"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="last"
                      size="sm"
                      v-model="purchasepayment.ext.last"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款类别"
                    label-for="payment_type"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required" name="付款类别" #default="{ errors }">
                    <v-select
                        id="payment_type"
                        :options="getCodeOptions('purchase_payment_type')"
                        :clearable="true"
                        v-model="paymentType"
                        @input="changeSelect('payment_type',$event)"
                        class="select-size-sm"
                        placeholder="请选择付款类别"
                        :disabled="purchasepayment.order_id!=undefined"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="本次申请付款比例"
                    label-for="pay_percent"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="pay_percent"
                      size="sm"
                      v-model="pay_percent"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="累计已付款金额(PO币)"
                    label-for="total_payed"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="total_payed"
                      size="sm"
                      v-model="total_payed"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="本次应付金额(PO币)"
                    label-for="current_pay"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="current_pay"
                      size="sm"
                      v-model="current_pay"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="本次付款后欠款余额(PO币)"
                    label-for="current_remain"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="current_remain"
                      size="sm"
                      v-model="current_remain"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="本次申请付款金额(PO币)"
                    label-for="current_apply_pay"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required|positive" name="本次申请付款金额(PO币)" #default="{ errors }">
                    <b-form-input
                        id="current_apply_pay"
                        size="sm"
                        v-model="purchasepayment.current_apply_pay"
                        @change="changeMoney($event)"
                        placeholder="请填写本次申请付款金额(PO币)"
                        type="text"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="最晚付款时间"
                    label-for="last_paydate"
                    label-size="sm"
                    class="mb-1"
                >
                  <flat-pickr
                      v-model="purchasepayment.last_paydate"
                      class="form-control"
                      placeholder="请选择最晚付款时间时间"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="预计发票到票时间"
                    label-for="expected_invoice_date"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <ValidationProvider rules="required" name="预计发票到票时间" #default="{ errors }">
                    <flat-pickr
                        v-model="purchasepayment.expected_invoice_date"
                        class="form-control"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>

                </b-form-group>
              </b-col>
              <b-col md="4">

              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="付款事由"
                    label-for="pay_reason"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-textarea
                      id="remark"
                      v-model="purchasepayment.pay_reason"
                      rows="3"
                      max-rows="6"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <b-card header="收款方信息">
            <b-row>
              <b-col md="4">
                <b-row>
                  <b-col md="11">
                    <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="收款方供应商名称"
                        label-for="bank_supplier_name"
                        label-size="sm"
                        class="mb-1"
                    >
                      <b-form-input
                          id="bank_supplier_name"
                          size="sm"
                          v-model="purchasepayment.bank_supplier_name"
                          readonly
                      />

                    </b-form-group>
                  </b-col>
                  <b-col md="1" >
                    <feather-icon icon="PlusSquareIcon" v-b-modal.modal-bank size="30"/>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="银行账户类型"
                    label-for="bank_account_type"
                    label-size="sm"
                    class="mb-1 required"
                >
                    <b-form-input
                        id="bank_account_type"
                        size="sm"
                        v-model="purchasepayment.bank_account_type"
                        readonly
                    />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人户名"
                    label-for="bank_account_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bank_account_name"
                      size="sm"
                      v-model="purchasepayment.bank_account_name"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人账号"
                    label-for="bank_account_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bank_account_no"
                      size="sm"
                      v-model="purchasepayment.bank_account_no"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款行及支行名称"
                    label-for="bank_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bank_name"
                      size="sm"
                      v-model="purchasepayment.bank_name"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="银行地址"
                    label-for="bank_node_address"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bank_node_address"
                      size="sm"
                      v-model="purchasepayment.bank_node_address"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="联行号"
                    label-for="bank_swift_code"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bank_swift_code"
                      size="sm"
                      v-model="purchasepayment.bank_swift_code"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否境外"
                    label-for="bank_is_outlands"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="bank_is_outlands"
                      v-model="purchasepayment.bank_is_outlands"
                      value="1"
                      unchecked-value="0"
                      disabled
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <b-card header="其他信息">
            <b-row>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="备注"
                    label-for="remark"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-textarea
                      id="remark"
                      v-model="purchasepayment.remark"
                      rows="3"
                      max-rows="6"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="相关附件"
                    label-for="attachments"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="purchasepayment.loaded" :theme="'files'"
                                     :attachment_id="'attachments'"
                                     :id="purchasepayment.attachments"
                                     :object_type="'purchase_payment'"
                                     :object_id="0"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="ERP单据截图"
                    label-for="attachments_erpimage"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="purchasepayment.loaded" :theme="'files'"
                                     :attachment_id="'attachments_erpimage'"
                                     :id="purchasepayment.attachments_erpimage"
                                     :object_type="'purchase_payment'"
                                     :object_id="0"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="发票附件上传"
                    label-for="attachments_bill"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="purchasepayment.loaded" :theme="'files'"
                                     :attachment_id="'attachments_bill'"
                                     :id="purchasepayment.attachments_bill"
                                     :object_type="'purchase_payment'"
                                     :object_id="0"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">

              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
              v-if="readonly === 0 &&(purchasepayment.status==0||purchasepayment.status==3||purchasepayment.status==6)"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>


        <b-modal
            id="modal-bank"
            ok-only
            ok-title="确认"
            @ok="onSelectOurBank"
            cancel-title="取消"
            centered
            size="lg"
            title="选择银行"

        >
          <bank-account-list
              ref="ourBankSelects" :companyId="supplierid"
              v-on:table="fromChildren($event,'modal-bank')"
          >
          </bank-account-list>
        </b-modal>
      </b-row>
    </b-form>
    </ValidationObserver>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchasepaymentStore from './purchasepaymentStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
import purchaseorderStore from "@/views/apps/purchaseorder/purchaseorderStore";
import companyStore from "@/views/apps/company/companyStore";
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import BankAccountList from "@/views/apps/bankaccount/bankaccount-modal/BankAccountList";
import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
    BankAccountList,
  },
  data() {
    return {
      id: ref(0),
      purchasepayment: ref({}),
      orderId: 0,
      purchaseorder: {},
      companys: [],
      ourCompanyName: '',
      typeId:'',
      expressTicketType:'',
      invoiceType:'',
      taxRate:'',
      paymentMethod:'',
      paymentType:'',
      pay_percent:'',
      current_pay:'',
      current_remain:'',
      total_pay:'',
      total_payed:'',
      supplierid:0,
      bankaccountno:'',
      readonly:0,
      is_prepay:"",
    }
  },
  methods:{
    onSelectOurBank(modal) {
      let checked = modal['new'] ==undefined?this.$refs.ourBankSelects.getSelected()[0]:modal;
      this.purchasepayment.bank_account_type = getCodeLabel("bank_account_type",checked.account_type)
      this.purchasepayment.bank_is_outlands = checked.is_outlands
      this.purchasepayment.bank_account_name = checked.account_name
      this.purchasepayment.bank_account_no = checked.account_no
      this.purchasepayment.bank_name = checked.bank_name
      this.purchasepayment.bank_node_address = checked.bank_node_address
      this.purchasepayment.bank_swift_code = checked.swift_code
      this.bankaccountno = checked.account_no
      this.$forceUpdate()
    },
    accAdd (arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    },
    accSub (arg1,arg2) {
      let r1,r2,m,n;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2));
      n=(r1>=r2)?r1:r2;
      return ((arg1*m-arg2*m)/m).toFixed(n);
    },
  },
  watch:{
    is_prepay:function (val, oldVal) {

    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchasepayment')) store.registerModule('purchasepayment', purchasepaymentStore)
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchasepayment')) store.unregisterModule('purchasepayment')
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('company')) store.unregisterModule('company')
    })

    const edit = function () {
      store.dispatch('purchasepayment/edit', {id: this.id,orderid:this.orderId}).then(res => {
        if (res.data.code == 1) {
          toast.error(res.data.data)
          this.$router.push({name: 'apps-purchaseorder-list'})
        }
        this.purchasepayment = res.data.data
        searchPurchaseOrderById(this.orderId, this.purchaseorder)
        searchCompany(this.companys)
        if (this.purchasepayment.is_prepay === undefined){
          if ( this.purchasepayment.ext.canUse > 0){
            this.is_prepay = getCode("yesno","1")
            this.purchasepayment.is_prepay = 1
          }else{
            this.is_prepay = getCode("yesno","0")
            this.purchasepayment.is_prepay = 0
          }
        }else {
          this.is_prepay = getCode("yesno",this.purchasepayment.is_prepay)
        }

        if(this.purchasepayment.new){
          this.ourCompanyName = this.purchasepayment.purchase_company_name
          this.purchasepayment.pay_company_name = this.purchasepayment.purchase_company_name
          this.purchasepayment.pay_company_id = this.purchasepayment.purchase_company_id
        }else {
          this.ourCompanyName = getCodeLabel("company",this.purchasepayment.pay_company_id)
        }
        this.typeId = getCodeLabel("business_type",this.purchasepayment.type_id)
        this.expressTicketType = getCodeLabel("express_ticket_type",this.purchasepayment.express_ticket_type)
        this.invoiceType = getCodeLabel("invoice_type",this.purchasepayment.invoice_type)
        this.taxRate = getCodeLabel("tax_rate",this.purchasepayment.tax_rate)

        this.paymentMethod = getCodeLabel("agreement_pay_method",this.purchasepayment.payment_method)
        this.paymentType = getCodeLabel("purchase_payment_type",this.purchasepayment.payment_type)
        this.purchasepayment.last_paydate = toDate(this.purchasepayment.last_paydate)
        this.purchasepayment.expected_invoice_date = !this.purchasepayment.expected_invoice_date ? this.purchasepayment.expected_invoice_date : toDate(this.purchasepayment.expected_invoice_date)
        this.supplierid = res.data.data.ext.supplierid
        this.pay_percent = this.purchasepayment.pay_percent
        this.current_pay = this.purchasepayment.current_pay
        this.current_remain = this.purchasepayment.current_remain
        this.total_pay = this.purchasepayment.total_pay
        this.total_payed = this.purchasepayment.total_payed
      })
    }

    const view = function () {
      store.dispatch('purchasepayment/view', {id: this.id}).then(res => {
        this.purchasepayment = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      // if (this.purchasepayment.pay_company_name === ""||this.purchasepayment.pay_company_name===undefined){
      //   toast.error("请选择 付款主体！")
      //   return false
      // }
      if(this.current_remain != undefined && this.current_remain != null && this.current_remain != '' && parseFloat(this.current_remain)<0){
        alert("本次付款后欠款余额(PO币）必须>=0")
        return false;
      }
      if(parseFloat(this.accAdd(this.total_payed,this.purchasepayment.current_apply_pay))>parseFloat(this.total_pay)){
        alert("累计已付款金额(PO币)+本次申请付款金额(PO币)须<=订单金额")
        return false;
      }
      if (this.purchasepayment.order_id==undefined){
        store.dispatch('purchasepayment/isCreated', {order_id: this.purchasepayment.purchase_order_id,payment_type:this.purchasepayment.payment_type}).then(res => {
          if (res.data.code==0){
            this.$refs.observer.validate().then(success =>{
              if (success){
                if (this.purchasepayment.bank_account_type === ""||this.purchasepayment.bank_account_type===undefined){
                  toast.error("请选择 收款人银行账户信息！")
                  return false
                }
                this.purchasepayment.last_paydate = second(this.purchasepayment.last_paydate)
                this.purchasepayment.expected_invoice_date = second(this.purchasepayment.expected_invoice_date)
                store.dispatch('purchasepayment/save', this.purchasepayment).then(res => {
                  if (res.data.code==0){
                    toast.success('数据已保存!')
                    this.$router.push({name: 'apps-purchasepayment-list',query:{order_id:this.purchasepayment.purchase_order_id}});
                  }else {
                    toast.error("保存失败!")
                  }
                })
              }else {
                toast.error("请填写必填字段!")
              }
            })
          }else {
            toast.error(res.data.data)
          }

        })
      }else {
        this.$refs.observer.validate().then(success =>{
          if (success){
            if (this.purchasepayment.bank_account_type === ""||this.purchasepayment.bank_account_type===undefined){
              toast.error("请选择 收款人银行账户信息！")
              return false
            }
            this.purchasepayment.last_paydate = second(this.purchasepayment.last_paydate)
            this.purchasepayment.expected_invoice_date = second(this.purchasepayment.expected_invoice_date)
            store.dispatch('purchasepayment/save', this.purchasepayment).then(res => {
              if (res.data.code==0){
                toast.success('数据已保存!')
                this.$router.go(-1)
              }else {
                toast.error("保存失败!")
              }
            })
          }else {
            toast.error("请填写必填字段!")
          }
        })
      }
    }

    const searchPurchaseOrderById = function (params, item) {
      store.dispatch('purchaseorder/search', {order_id: params}).then(res => {
        item = res.data.data.list[0]
      })
    }

    const searchCompany = function (params) {
      store.dispatch('company/searchGroup').then(res => {
        const data = res.data.data.list
        for (let i = 0; i < data.length; i++) {
          params.push({"label": data[i].company_name, "code": data[i].company_id})
        }
      })
    }

    const setOurCompanyName = function (event) {
      if (event!=undefined){
        this.purchasepayment.pay_company_name = event.label
        this.purchasepayment.pay_company_id = event.code
      }else {
        this.purchasepayment.pay_company_name = ""
        this.purchasepayment.pay_company_id = ""
      }

    }

    const changeSelect = function (key, event) {
      if (event!=undefined){
        this.purchasepayment[key] = event.value
      }else {
        this.purchasepayment[key] = ''
      }
      if (key=="payment_type"){
        this.purchasepayment.current_apply_pay=null;
        this.current_remain=this.accSub(this.total_pay,this.total_payed)
        this.purchasepayment.current_remain=this.accSub(this.total_pay,this.total_payed)
        store.dispatch('purchasepayment/isCreated', {order_id: this.purchasepayment.purchase_order_id,payment_type:event.value}).then(res => {
          if (res.data.code===0){
            store.dispatch('purchasepayment/changeMoney', {id:this.purchasepayment.purchase_order_id,type:event.value,current_apply_pay:this.purchasepayment.current_apply_pay}).then(res => {
              let data = res.data.data
              this.purchasepayment.pay_percent = data.pay_percent
              this.purchasepayment.current_pay = data.current_pay
              this.purchasepayment.current_remain = data.current_remain
              this.purchasepayment.total_pay = data.total_pay
              this.purchasepayment.total_payed = data.total_payed

              this.pay_percent = data.pay_percent
              this.current_pay = data.current_pay
              this.current_remain = data.current_remain
              this.total_pay = data.total_pay
              this.total_payed = data.total_payed
            })
          } else {
            toast.error(res.data.data)
          }
        })
      }

    }

    const onUploaded = function (id, attachment, result) {
      this.purchasepayment[id] = result
    }

    const changeMoney = function (event) {
      let amount = 0;
      if (this.current_pay==null){
        toast.error("请先选择付款类别!")
        this.purchasepayment.current_apply_pay=null
      }
      if (parseInt(event)>this.current_pay){
        toast.error("超出最大金额!")
        this.purchasepayment.current_apply_pay=this.current_pay
        if(this.purchasepayment.current_apply_pay!=null){
          amount = this.purchasepayment.current_apply_pay
        }
        this.current_remain=this.accSub(this.accSub(this.total_pay,this.total_payed),amount)
        this.purchasepayment.current_remain=this.accSub(this.accSub(this.total_pay,this.total_payed),amount)
        return
      }
     /* console.log(this.is_prepay.value)
      console.log(this.purchasepayment.ext.canUse)*/
      if (this.is_prepay.value == '1' && parseInt(event)>this.purchasepayment.ext.canUse){
        toast.error("超出预付款最大可使用金额!")
        this.purchasepayment.current_apply_pay=this.purchasepayment.ext.canUse

      }
      if(this.purchasepayment.current_apply_pay!=null){
        amount = this.purchasepayment.current_apply_pay
      }
      this.current_remain=this.accSub(this.accSub(this.total_pay,this.total_payed),amount)
      this.purchasepayment.current_remain=this.accSub(this.accSub(this.total_pay,this.total_payed),amount)
    }

    const fromChildren = function (checked,modal) {
      switch (modal) {
          case "modal-bank":{
            this.onSelectOurBank(checked)
            break
          }
      }
      this.$bvModal.hide(modal)
    }


    return {
      edit,
      view,
      cancel,
      save,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toDate,
      toTime,
      second,

      searchPurchaseOrderById,
      searchCompany,
      setOurCompanyName,
      changeSelect,
      onUploaded,
      changeMoney,
      fromChildren,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.orderId = this.$route.query.order_id || 0;
    this.readonly = this.$route.query.readonly || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
